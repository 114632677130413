@import '@styles/colors.module.scss';

.h1 {
  font-size: 40px;
  line-height: 44px;
  font-weight: 700;
  color: $text;
  margin: 0;
}
.h2 {
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
  color: $text;
  margin: 0;
}
.h3 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: $text;
  margin: 0;
}
.h4 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  color: $text;
  margin: 0;
}

.body1 {
  font-size: 16px;
  line-height: 20px;
  color: $text;
  margin: 0;
}
.body2 {
  font-size: 14px;
  line-height: 18px;
  color: $text;
  margin: 0;
}
.subtitle1 {
  font-size: 16px;
  line-height: 20px;
  color: $textLightGray;
  margin: 0;
}
.subtitle2 {
  font-size: 14px;
  line-height: 18px;
  color: $label;
  margin: 0;
}
.a {
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
}

.bold {
  font-weight: bold;
}

.center {
  text-align: center;
}
