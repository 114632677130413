@import '@styles/colors.module.scss';
@import '@styles/mixins/media';

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
html,
body {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

main {
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
}

input {
  font-family: 'DM Sans', sans-serif;
}

.disableScrollbar {
  overflow: hidden;
}

.rotate90 {
  transform: rotate(90deg);
  transition: all 0.2s;
}

.rotate180 {
  transform: rotate(180deg);
  transition: all 0.2s;
}

.rotate270 {
  transform: rotate(270deg);
  transition: all 0.2s;
}
.react-video-thumbnail-image > img {
  object-fit: cover;
  height: 100%;
}
.react-video-thumbnail-image {
  height: 75%;

  @include media('tablet') {
    height: 100%;
  }
}

.hideOnMobile {
  display: none;
  @include media('tablet') {
    display: block;
  }
}

.hideOnDesktop {
  @include media('tablet') {
    display: none;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
