*,*::after,*::before{margin:0;padding:0;box-sizing:border-box;-webkit-tap-highlight-color:rgba(0,0,0,0)}html,body{padding:0;margin:0;scroll-behavior:smooth}main{width:100%;height:100%}a{text-decoration:none}input{font-family:"DM Sans",sans-serif}.disableScrollbar{overflow:hidden}.rotate90{transform:rotate(90deg);transition:all .2s}.rotate180{transform:rotate(180deg);transition:all .2s}.rotate270{transform:rotate(270deg);transition:all .2s}.react-video-thumbnail-image>img{object-fit:cover;height:100%}.react-video-thumbnail-image{height:75%}@media only screen and (min-width: 768px){.react-video-thumbnail-image{height:100%}}.hideOnMobile{display:none}@media only screen and (min-width: 768px){.hideOnMobile{display:block}}@media only screen and (min-width: 768px){.hideOnDesktop{display:none}}.ellipsis{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;max-width:100%}
.h1{font-size:40px;line-height:44px;font-weight:700;color:#292929;margin:0}.h2{font-size:32px;line-height:36px;font-weight:700;color:#292929;margin:0}.h3{font-size:20px;line-height:24px;font-weight:700;color:#292929;margin:0}.h4{font-size:18px;line-height:22px;font-weight:700;color:#292929;margin:0}.body1{font-size:16px;line-height:20px;color:#292929;margin:0}.body2{font-size:14px;line-height:18px;color:#292929;margin:0}.subtitle1{font-size:16px;line-height:20px;color:#969696;margin:0}.subtitle2{font-size:14px;line-height:18px;color:#636363;margin:0}.a{font-size:14px;line-height:14px;font-weight:500}.bold{font-weight:bold}.center{text-align:center}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_5cb4d6';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_5cb4d6';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_5cb4d6';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_5cb4d6';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_5cb4d6';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_5cb4d6';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__DM_Sans_Fallback_5cb4d6';src: local("Arial");ascent-override: 94.72%;descent-override: 29.60%;line-gap-override: 0.00%;size-adjust: 104.73%
}.__className_5cb4d6 {font-family: '__DM_Sans_5cb4d6', '__DM_Sans_Fallback_5cb4d6';font-style: normal
}

